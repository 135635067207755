import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Faq from "../components/faq/faq";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import News from "../components/news/news";
import ContentWithImage from "../components/products/contentWithImage/contentWithImage";
import Hero from "../components/products/hero/hero";
import Percent from "../components/products/percent/percent";
import Seo from "../components/seo";
import DemoButton from './../components/demoButton/demoButton';
import Testimonial from "../components/products/testimonial/testimonial";
import Partners from "../components/products/partners/partners";
import ContactForm from "../components/products/contactForm/contactForm";
import Percents from './../components/products/percents/percents';


// markup
const LoyaltyRewardProgramPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const excerpt = ``;

  const heroBlock = {
    title: <>Transform Customer Engagement with<br /> a Robust <span style={{ backgroundImage: 'linear-gradient(92deg, #FA6A01 27.6%, #F3761D 98.77%)' }}>loyalty rewards</span> program.</>,
    desktop: {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-reward-program"
          src="../images/loyalty-reward-program/hero.png"
          className="mw-100"
        />
      ),
      smallImageStyle: {
        bottom: '80px',
        right: '100px',
        maxWidth: '500px',
      },
      smallImage: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-reward-program"
          src="../images/loyalty-reward-program/hero-floating.png"
          className="mw-100"
        />
      )
    },
    mobile: {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-reward-program"
          src="../images/loyalty-reward-program/hero.png"
          className="mw-100"
        />
      ),
      smallImageStyle: {},
      smallImage: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-reward-program"
          src="../images/loyalty-reward-program/hero-floating.png"
          style={{ maxWidth: '300px' }}
        />
      )
    },
    description: <></>,
  }

  const localListings = {
    reverse: false,
    badge: false,
    title: <><span style={{ backgroundImage: 'linear-gradient(90deg, #FF6C02 0.1%, #D84C02 62.75%)' }}>Loyalty Reward<br /> Program</span></>,
    description: <>Shift from cash-back to a points-per-<br/>dollar-spent system.</>,
    button: <DemoButton scrollToForm={scrollToForm} classes={[]}>Schedule a DEMO</DemoButton>,
    items: [
      {
        type: 'titleOnly',
        icon: false,
        title: <>Customer<br /> Retention</>,
        description: false,
      },
      {
        type: 'titleOnly',
        icon: false,
        title: <>Increase Customer<br /> Spending</>,
        description: false,
      },
      {
        type: 'titleOnly',
        icon: false,
        title: <>Earn on<br /> Every $ Spent</>,
        description: false,
      },
      {
        type: 'titleOnly',
        icon: false,
        title: <>Increase Repeat<br /> Visits</>,
        description: false,
      },
      {
        type: 'titleOnly',
        icon: false,
        title: <>Draw More Traffic<br /> to your Site</>,
        description: false,
      },
      {
        type: 'titleOnly',
        icon: false,
        title: <>Offer a customer<br /> signup bonus</>,
        description: false,
      },
    ],
    addAfterItems: false,
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-programm"
          src="../images/loyalty-reward-program/loyalty-programm.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-programm"
          src="../images/loyalty-reward-program/loyalty-programm.png"
          className="mw-100"
        />
      ),
    },
  }

  const percentsBlock = {
    title: false,
    items: [
      {
        percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>83%</span></>,
        description: <>Of consumers prefer<br /> to order direct</>
      },
      {
        percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>47%</span></>,
        description: <>Use loyalty rewards<br /> several times a month</>
      },
      {
        percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>32%</span></>,
        description: <>Use loyalty rewards<br /> several times a week</>
      },
    ],
  }

  const testimonial = {
    title: <>
      <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6B04 -1.33%, #EA5B04 45.68%)' }}>Customer </span> Feedback
    </>,
    place: <>Latha African Style BBQ</>,
    person: <>Maria Albegiani - Owner</>,
    review: <>
      We've been working with Kevin [account manager], one-on-one, making sure we do things the right way.
      I can't complain, things are doing better, we are doing better. It's 75% improvement of what we had before.
    </>,
    button: <Link to={'/customer-stories/'} className='more-link'>Explore more stories</Link>,
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="Latha-African-Style-BBQ"
          src="../images/loyalty-reward-program/testimonial.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="Latha-African-Style-BBQ"
          src="../images/loyalty-reward-program/testimonial.png"
          className="mw-100"
        />
      ),
    },
  }

  const questions = [
    {
      question: `How does the program work?`,
      answer: `N/A`,
    },
    {
      question: `Why offer points-per-dollar?`,
      answer: `N/A`,
    },
    {
      question: `How does it draw more traffic to my site?`,
      answer: `N/A`,
    },
    {
      question: `Can I control how much I offer customers?`,
      answer: `N/A`,
    },
    {
      question: `Can I create event-based deals?`,
      answer: `N/A`,
    },
  ];
  const contactBlock = {
    // title: <>Take The <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6B01 27.81%, #B12D01 52.37%)' }}>First Step</span> Towards A More<br /> Powerful Ordering Solution</>,
  }

  return (
    <>
      <Seo
        title={"Loyalty Reward Program"}
        description={excerpt}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Hero block={heroBlock} />
        <ContentWithImage block={localListings} />
        <Percents block={percentsBlock} />
        <Testimonial block={testimonial} />
        <Partners />
        <News />
        <Faq items={questions} />
        <div ref={formRef}>
          <ContactForm block={contactBlock} />
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "loyalty-reward-program" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "menu-management"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default LoyaltyRewardProgramPage;
